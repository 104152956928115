// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bD_k3";
export var caseStudyBackground__bgColor = "bD_k5";
export var caseStudyBackground__lineColor = "bD_k0";
export var caseStudyCta__bgColor = "bD_l4";
export var caseStudyHead__imageWrapper = "bD_kX";
export var caseStudyProjectsSection = "bD_k4";
export var caseStudyQuote__bgLight = "bD_kY";
export var caseStudyQuote__bgRing = "bD_kZ";
export var caseStudyTechSection = "bD_ld";
export var caseStudyVideo = "bD_k1";
export var caseStudyVideo__ring = "bD_k2";
export var caseStudy__bgDark = "bD_kV";
export var caseStudy__bgLight = "bD_kT";